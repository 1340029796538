import "promise-polyfill/src/polyfill";

// UX components
import VueSilentbox from "vue-silentbox";
import VueAgile from "vue-agile";
import Autocomplete from "@trevoreyre/autocomplete-vue";
// Font Awesome
import {library} from "@fortawesome/fontawesome-svg-core";
import {faFacebookF, faInstagram, faLinkedinIn, faTwitter} from "@fortawesome/free-brands-svg-icons";
import Swiper from "swiper";
import "swiper/swiper.css";
import { Autoplay, Controller, Navigation } from 'swiper/modules';


import {
    faArrowRight,
    faBus,
    faChartLine,
    faCheckCircle,
    faChild,
    faClock,
    faEdit,
    faEnvelope,
    faEuroSign,
    faEye,
    faEyeSlash,
    faFileDownload,
    faGraduationCap,
    faHandshake,
    faHome,
    faInfoCircle,
    faLaptop,
    faLocation,
    faLockAlt,
    faLongArrowLeft,
    faLongArrowRight,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faNewspaper,
    faParking,
    faPhone,
    faSearch,
    faShoppingBag,
    faShoppingCart,
    faTimes,
    faTrash,
    faTreePalm,
    faUser,
    faUsersClass,
    faWifi,
    faBars,
} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

// Vee-validate
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {email, required, required_if} from "vee-validate/dist/rules";
import validateInsz from "validate-insz";

// Tooltip
import VTooltip from "v-tooltip";

// Mixitup
import mixitup from "mixitup";
import mixitupMultifilter from "../vendor/mixitup-multifilter";
import mixitupPagination from "../vendor/mixitup-pagination";

// Light Galery
import "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.css";
// Import our CSS
import styles from "../css/app.pcss";

const VueInputMask = require("vue-inputmask").default;

// Vee-validate rules
extend("required", {
    ...required,
    message: "Dit veld is vereist."
});

extend("required_if", {
    ...required_if,
    message: "Dit veld is vereist."
});

extend("email", {
    ...email,
    message: "Dit is geen geldig e-mail adres."
});

extend("date", {
    validate: value => /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/.test(value),
    message: "De datum staat niet in het juiste formaat dd/mm/yyyy"
});

extend("insz", {
    validate: value => validateInsz(value),
    message: "Dit is geen geldig INSZ nummer"
});

library.add(faUsersClass, faNewspaper, faTimes, faFacebookF, faTwitter, faLinkedinIn, faInstagram, faSearch, faLaptop, faGraduationCap, faChartLine, faChild, faHome, faClock, faLocation, faCheckCircle, faEdit, faEuroSign, faLockAlt, faShoppingBag, faShoppingCart, faFileDownload, faInfoCircle, faTrash, faLongArrowRight, faLongArrowLeft, faUser, faArrowRight, faPhone, faEnvelope, faMapMarkedAlt, faEye, faEyeSlash, faMapMarkerAlt, faHandshake, faWifi, faParking, faBus, faTreePalm, faBars);

// App main
const main = async () => {

    // Async load the vue module
    const {"default": Vue} = await import(/* webpackChunkName: "vue" */ "vue");
    const {"default": axios} = await import( /* webpackChunkName: "axios" */ "axios");
    const vClickOutside = await import( /* webpackChunkName: "v-click-outside" */ "v-click-outside");
    const {"default": zxcvbn} = await import( /* webpackChunkName: "zxcvbn" */ "zxcvbn");

    window.mixitup = mixitup;
    window.mixitup.use(mixitupMultifilter);
    window.mixitup.use(mixitupPagination);
    window.zxcvbn = zxcvbn;

    Vue.component("font-awesome-icon", FontAwesomeIcon);
    Vue.use(VueSilentbox);
    Vue.use(VueAgile);
    Vue.use(VTooltip);
    Vue.use(Autocomplete);
    Vue.use(VueInputMask);

    Vue.component("ValidationObserver", ValidationObserver);
    Vue.component("ValidationProvider", ValidationProvider);

    window.axios = axios;

    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ["${", "}"],
        directives: {
            clickOutside: vClickOutside.directive
        },
        components: {},
        data: {
            // Navigation
            isMenuOpen: false,
            mobileLevelsOpen: [],
            mobileNavLinkClickedActive: false,

            showMetaMix: false,
            metamix: false,
            metamixFirstColumn: false,
            activeMetamix: false,

            showLocationMix: false,
            locationMetamix: false,
            activeLocationMix: false,

            passwordFieldType: "password",

            // Course table
            activeCourseTableTabs: "offer",

            // Login
            loginName: "",
            password: null,
            newPassword: null,
            passwordScore: 0,

            // Register
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address1: "", // Straat
            address2: "", // Nummer
            address3: "", // Bus
            zipCode: "",
            city: "",
            cityId: 0,
            countryId: "",
            gender: "",
            nationality: "",
            birthday: "",
            cityOfBirth: "",
            countryOfBirth: "",
            economicStatus: "",
            diplomaCountry: "",
            nationalRegisterNumber: "",
            educationLevel: "",
            remarks: "",
            optin: "",
            custom1: "",
            naturalLanguage: "",
            cityError: false,
            isBelgium: true,

            // Cart
            totalQty: 0,

            // Flash messages
            flashMsg: "",
            flashType: false,

            // Search popup
            searchMode: false,

            // Search page
            searchResultsActiveTab: "courses"
        },

        methods: {

            toggle() {
                this.isMenuOpen = !this.isMenuOpen;
                this.$nextTick(() => {
                    this.$refs.toggleButton.focus();
                });
            },

            deleteOrderLine() {
                this.$refs.cartForm.submit();
            },

            async mobileNavLinkClicked(id, title) {
                // Check if the function is already running
                // If it does = exit the function || if it doesn't = say it is
                if (this.mobileNavLinkClickedActive) return;
                this.mobileNavLinkClickedActive = true;

                // try to get the mobile level
                let nextLevel = document.getElementById("lvl-" + id);

                // Create new level if it does not exist yet
                if (!nextLevel) {
                    // Get Children of clicked element
                    await window.axios.get(`/api/get-mobile-level?pageId=${id}`).then(respone => {
                        // Create new div view with children of clicked element and append it to the dom
                        const mobileMenuContainer = {
                            props: ["pageId", "pageTitle", "pages", "mobileNavBackClicked", "mobileNavLinkClicked"],
                            template: `
                                <nav class="menu" :id="'lvl-' + pageId">
                                    <div @click="mobileNavBackClicked" class="menu__go-back">
                                        <font-awesome-icon :icon="['fal', 'long-arrow-left']"></font-awesome-icon>
                                        <span>Terug</span>
                                    </div>
                                    <strong class="menu__title">{{ pageTitle }}</strong>
                                    <ul class="menu__list">
                                        <li class="menu__item" 
                                            v-for="child in pages" :key="child.id"
                                            v-if="child.hasChildren" 
                                            @click.prevent="mobileNavLinkClicked(child.id, child.title)"
                                        >
                                            <a href="#" class="menu__link menu__link--has-children">
                                                {{ child.title }}
                                                <font-awesome-icon
                                                    :icon="['fal', 'long-arrow-right']"></font-awesome-icon>
                                            </a>
                                        </li>
                                        <li class="menu__item" v-else>
                                            <a class="menu__link" :href="child.url">{{ child.title }}</a>
                                        </li>
                                    </ul>
                                </nav>
                            `
                        };
                        const Page = Vue.extend(mobileMenuContainer);
                        const vm = new Page({
                            propsData: {
                                pageId: id,
                                pageTitle: title,
                                pages: respone.data.children,
                                mobileNavBackClicked: this.mobileNavBackClicked,
                                mobileNavLinkClicked: this.mobileNavLinkClicked
                            }
                        }).$mount();
                        document.querySelector(".mobile-navigation").appendChild(vm.$el);
                        console.log("creating new element");

                        // Search for the new element you created and add it to the levels array
                        nextLevel = document.getElementById("lvl-" + id);
                        console.log("targetting new element");
                    });
                }

                // Save level
                this.mobileLevelsOpen.push(nextLevel);
                console.log(this.mobileLevelsOpen);

                // Show level
                // Adding a timeout
                nextLevel.offsetHeight; // Trigger repaint
                nextLevel.classList.add("menu--show");
                console.log("adding show class...");


                // Add active class only to the last panel shown
                // Active class makes the div go in top
                this.mobileLevelsOpen.forEach(el => {
                    el.classList.remove("menu--active");
                });
                nextLevel.classList.add("menu--active");

                this.mobileNavLinkClickedActive = false;
            },

            mobileNavBackClicked() {
                // Set current level
                let currentLevel = this.mobileLevelsOpen[this.mobileLevelsOpen.length - 1];

                // Remove show class and transition out
                currentLevel.classList.remove("menu--show");
                setTimeout(() => {
                    currentLevel.classList.remove("menu--active");
                }, 350); // Transition default = 300ms


                // Remove level from array
                this.mobileLevelsOpen.pop();
                console.log(this.mobileLevelsOpen);
            },

            closeFlashMessage(type) {
                if (type === "vue") {
                    this.flashMsg = false;
                } else {
                    this.$refs.flashMessage.remove();
                }
            },

            addToCart(e) {

                let vm = this;

                // Submit form via axios
                let bodyFormData = new FormData(e.target);
                window.axios({
                    method: "post",
                    url: "/",
                    data: bodyFormData,
                    headers: {"Content-Type": "multipart/form-data"}
                })
                    .then(response => {
                        if (response.data.success === true) {
                            vm.totalQty = response.data.cart.totalQty;
                            vm.flashMsg = bodyFormData.get("cartUpdatedNotice");
                            vm.flashType = "bg-lbc-green";
                        } else {
                            var errors = [];
                            if (Object.keys(response.data.errors).length) {
                                for (const [key, value] of Object.entries(response.data.errors)) {
                                    var errorMessage = value[0];
                                    if (errorMessage === "Maximum order quantity for this item is 1.") {
                                        errors.push("Je kan maar éénmaal inschrijven op een cursus. Deze cursus staat al in jouw winkelmand.");
                                    } else {
                                        errors.push(errorMessage);
                                    }
                                }
                            }
                            vm.flashMsg = errors.join(" ");
                            vm.flashType = "bg-orange-500";
                        }
                    })
                    .catch(response => console.log(response));
            },

            openLocationsMix(slug, pageId) {
                this.showLocationMix = true;
                this.showMetaMix = false;

                // Certain pages need to open specific pages (mostly the first level)
                switch (slug) {
                    case 'leslocaties':
                        pageId = 15775;
                        slug = 'antwerpen';
                        break;
                }
                // Set active state
                this.activeLocationMix = slug;

                // Do call
                window.axios.get("/api/locations?locationId=" + pageId).then(response => {
                    this.locationMetamix = response.data.children;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                });
            },

            closeLocationsMix(e) {
                if (!e.target.classList.contains("location-link")) {
                    this.showLocationMix = false;
                }
            },

            openMetamix(slug, pageId) {
                this.showMetaMix = true;
                this.showLocationMix = false;

                // Certain pages need to open specific pages (mostly the first level)
                switch (slug) {
                    case 'vind-je-opleiding':
                    case 'aanbod':
                        pageId = 214651;
                        slug = 'nieuwe-opleidingen';
                        break;
                    case 'cursussen':
                        pageId = 521;
                        slug = 'talen-leren';
                        break;
                }

                // Set active state
                this.activeMetamix = slug;

                // Do call
                window.axios.get("/api/descendants?pageId=" + pageId).then(response => {
                    this.parseMetaMixResponse(response);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                });
            },

            closeMetamix(e) {
                if (!e.target.classList.contains("metamix-link")) {
                    this.showMetaMix = false;
                }
            },

            openMetamixPanel(slug, pageId) {
                this.activeMetamix = slug;
                window.axios.get("/api/descendants?pageId=" + pageId).then(response => this.parseMetaMixResponse(response));
            },

            parseMetaMixResponse(response) {
                var data = response.data.children;
                var firstColumnData = data.filter(item => item.firstColumn === true);
                if (firstColumnData.length > 0) {
                    this.metamixFirstColumn = firstColumnData;
                    // Remove from response array
                    data = data.splice(data.findIndex(item => item.firstColumn === true), 1);
                } else {
                    this.metamixFirstColumn = false;
                }
                this.metamix = response.data.children;
            },

            onSubmit(e) {
                e.target.submit();
            },

            onRegister(e) {
                // Doing my own validation because autocomplete-vue & vee-validate do not play nice together
                if (this.custom1 === "") {
                    // Show Postcode & gemeente error
                    this.cityError = true;
                } else {
                    e.target.submit();
                }
            },

            switchPasswordVisibility() {
                this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            },

            searchModeOn() {
                this.searchMode = true;
            },

            searchModeOff() {
                this.searchMode = false;
            },

            calculatePassword() {
                this.passwordScore = window.zxcvbn(this.password).score;
            },

            searchCities(input) {
                return new Promise(resolve => {
                    if (input.length < 3) {
                        return resolve([]);
                    }
                    window.axios.get("/actions/lbc-module/frontend/search-cities?q=" + encodeURI(input))
                        .then(response => {
                            let returnData = [];
                            Object.values(response.data).forEach(value => {
                                returnData.push(value);
                            });
                            console.log(returnData);
                            resolve(returnData);
                        });
                });
            },

            setCountry(event) {
                if (event.target.value == "20") {
                    this.isBelgium = true;
                } else {
                    this.isBelgium = false;
                }
            },

            getCityValue(result) {
                return result.GM_POSTNUMMER + " " + result.GM_DEELGEMEENTE.toLowerCase();
            },

            handleCitySubmit(result) {
                this.cityId = result.GM_ID;
                this.location = result.GM_ID;
            },

            handleRegisterCitySubmit(result) {
                this.custom1 = result.GM_ID;
                this.zipCode = result.GM_POSTNUMMER;
                this.city = result.GM_DEELGEMEENTE;
            },

            changeCourseTableTab(tabName) {
                this.activeCourseTableTabs = tabName;
            }

        },

        watch: {
            isMenuOpen: {
                immediate: true,
                handler(isMenuOpen) {
                    if (isMenuOpen) {
                        document.body.style.setProperty("overflow", "hidden");
                    } else {
                        document.body.style.removeProperty("overflow");
                    }
                }
            }
        },

        mounted() {
            let vm = this;

            // Init formie
            if (window.Formie) {
                window.Formie.initForms();
            }

            // Init swiper

            const testimonialBlockEls = document.querySelectorAll(".block-testimonials");

            for (let testimonialEl of testimonialBlockEls) {
                const imagesSwiperEl = testimonialEl.querySelector(".block-testimonials__images .swiper");
                const quotesSwiperEl = testimonialEl.querySelector(".block-testimonials__quotes .swiper");
                const nextEl = testimonialEl.querySelector(".swiper-button-next") || null;
                const prevEl = testimonialEl.querySelector(".swiper-button-prev") || null;

                const imagesSwiper = new Swiper(imagesSwiperEl, {
                    // configure Swiper to use modules
                    modules: [Autoplay, Controller, Navigation],
                    slidesPerView: 1,
                    loop: true,
                    autoplay: {
                      delay: 10000,
                    },
                    navigation: {
                        nextEl: nextEl,
                        prevEl: prevEl,
                    },
                });

                const quotesSwiper = new Swiper(quotesSwiperEl, {
                    modules: [Controller],
                    slidesPerView: 1,
                    loop: true,
                });

                imagesSwiper.controller.control = quotesSwiper;
                quotesSwiper.controller.control = imagesSwiper;
            }
/*            const imagesSwiperEl = document.querySelector(".masthead__image .swiper");
            const contentSwiperEl = document.querySelector(".masthead__news .swiper");

            const swiper = new Swiper('.swiper', {
                // configure Swiper to use modules
                modules: [Navigation],
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });*/

            // Select all fields with data attributes
            let fields = document.querySelectorAll("[data-initial]");
            if (fields.length) {
                fields.forEach(function (element) {
                    vm[element.name] = vm.$refs[element.name].dataset.initial;
                });
            }

            // Set totalQty
            if (vm.$refs.cartTotalQty !== undefined) {
                vm.totalQty = vm.$refs.cartTotalQty.dataset.totalqty;
            }

            // Open the right metamix
            if (typeof vm.$refs.siteHeader !== "undefined") {
                var metaSlug = vm.$refs.siteHeader.dataset.openmetamixslug;

                if (metaSlug !== "") {
                    if (window.innerWidth > 768) {
                        vm.$refs[metaSlug].click();
                    }
                }
            }

            let maps = document.querySelectorAll(".map");
            // Do we have a map?
            if (maps.length) {
                mapboxgl.accessToken = "pk.eyJ1IjoidGhlY3JhZnQiLCJhIjoiY2s5dmVuaTVsMGF1YTNscGcxdDk0anZ0MSJ9.wTUaKSy-0gTfrUabmcUTGg";
                maps.forEach(element => {

                    let lat = element.getAttribute("data-lat");
                    let lng = element.getAttribute("data-lng");

                    let map = new mapboxgl.Map({
                        container: element,
                        style: "mapbox://styles/mapbox/streets-v11",
                        center: [lng, lat],
                        zoom: 12
                    }).addControl(new mapboxgl.NavigationControl());
                    new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);
                });
            }

            // Modal support
            let modals = document.querySelectorAll(".modal");
            modals.forEach((el) => {
                lightGallery(el, {
                    selector: "this",
                    width: "700px",
                    height: "500px",
                    mode: "lg-fade",
                    addClass: "`fixed-size`",
                    counter: false,
                    download: false,
                    startClass: "",
                    speed: 500,
                    closable: true
                });
            });

            document.getElementsByClassName("lg-close").innerHTML = "x";

            // prefill courseLocation 'HouMeOpDeHoogte'-form
            const courseIntrest = document.getElementById("fields-singleLine3");
            const courseIntrestTitle = document.getElementById("pre-fill-course");
            if (!!courseIntrest & !!courseIntrestTitle) {
                courseIntrest.value = courseIntrestTitle.dataset.value;
            }

            // querystring checken op showSearchResultType
            const urlParams = new URLSearchParams(window.location.search);
            // als showSearchResultType bestaat -> data attr goed zetten
            const myparam = urlParams.get("showSearchResultType");
            this.searchResultsActiveTab = (myparam === "pages") ? "pages" : "courses";
        }
    });

    return vm;
};

// Execute async function
main().then((vm) => {

    //document.body.classList.remove('loading')
    document.body.classList.add("loaded");

    /* Course-table mixit-up */
    let courseTableMixer;

    if (document.querySelector(".course-table-products__body--mixitup")) {
        const productsContainer = document.querySelector(".course-table-products__body--mixitup");
        courseTableMixer = window.mixitup(productsContainer, {
            debug: {enable: true},
            pagination: {limit: 8},
            multifilter: {enable: true},
            animation: {
                duration: 250,
                nudge: true,
                reverseOut: false,
                effects: "fade translateZ(-100px)",
                animateResizeContainer: false
            },
            callbacks: {
                onMixStart: function (state, futureState) {
                    /* Hide course-table on the "aanbod"-tab and show a message */
                    const courseFilters = document.querySelector(".course-table__filters");
                    const courseTable = document.querySelector(".course-table");
                    const noOfferText = document.querySelector(".no-offer-text");

                    if (futureState.activeFilter.selector === ".courseandsoftware" && futureState.totalShow === 0) {
                        courseFilters.classList.add("opacity-0", "h-0", "p-0");
                        courseFilters.classList.remove("p-4");
                        courseTable.classList.add("opacity-0", "h-0", "p-0");
                        courseTable.classList.remove("p-4");
                        noOfferText.classList.remove("opacity-0", "h-0");
                    } else {
                        courseFilters.classList.remove("opacity-0", "h-0", "p-0");
                        courseFilters.classList.add("p-4");
                        courseTable.classList.remove("opacity-0", "h-0", "p-0");
                        courseTable.classList.add("p-4");
                        noOfferText.classList.add("opacity-0", "h-0");
                    }
                }
            }
        });

        courseTableMixer.setFilterGroupSelectors("type", ".courseandsoftware");
        courseTableMixer.parseFilterGroups();
    }

    const blockTabs = document.querySelectorAll(".block-tabs--course-table .block-tabs__single");
    blockTabs.forEach(tab => {
        tab.addEventListener("click", function () {

            /* Reset filters */
            courseTableMixer.setFilterGroupSelectors("subtype", "");
            courseTableMixer.setFilterGroupSelectors("location", "");
            courseTableMixer.setFilterGroupSelectors("day", "");
            courseTableMixer.parseFilterGroups();

            selectElement("course-table-type", "");
            selectElement("course-table-day", "");
            selectElement("course-table-location", "");

            function selectElement(id, valueToSelect) {
                let element = document.getElementById(id);
                element.value = valueToSelect;
            }

            /* If the 'Aanbod' tab is click => filter on courses and software */
            if (tab.dataset.label === "products") {
                courseTableMixer.setFilterGroupSelectors("type", ".courseandsoftware");
                courseTableMixer.parseFilterGroups();
            }

            /* If the 'Info' tab is click => filter on infomoments */
            if (tab.dataset.label === "info-moments") {
                courseTableMixer.setFilterGroupSelectors("type", ".infomoment");
                courseTableMixer.parseFilterGroups();
            }
        });
    });

    /*mixitup-keyword-type--courseandsoftware*/


});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
